import React, { Component, Suspense, useEffect, useState } from "react";
import { HashRouter, Route, Routes, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./scss/style.scss";

// routes config
import adminRoute from "src/routes/admin_route";
import investorRoute from "src/routes/investor_route";
import { USER_ROLE } from "src/utils/constant_user_role";
import { getLoggedIn, updateSidebarShow } from "src/reducers/reducer_core";
import { useMediaQuery } from "react-responsive";

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Logout = React.lazy(() => import("./pages/logout/Logout"));
const Login = React.lazy(() => import("./pages/login/Login"));
const Register = React.lazy(() => import("./pages/register/Register"));
const Page404 = React.lazy(() => import("./pages/page404/Page404"));
const Page500 = React.lazy(() => import("./pages/page500/Page500"));
const ForgotPassword = React.lazy(() => import("./pages/forgot_password/ForgotPassword"));
const ForgotPasswordSent = React.lazy(() => import("./pages/forgot_password_sent/ForgotPasswordSent"));
const ResetPassword = React.lazy(() => import("./pages/reset_password/ResetPassword"));
const ResetPasswordSuccess = React.lazy(() => import("./pages/reset_password_success/ResetPasswordSuccess"));

function ShowSideBar() {
    const dispatch = useDispatch()
    const { pathname } = useLocation();
  
    useEffect(() => {
        dispatch(updateSidebarShow(true))
    }, [pathname]);
  
    return null;
}

const App = () => {
    const loggedIn = useSelector(getLoggedIn);
    const isDesktop = useMediaQuery({ minWidth: 992 })
    const [bodyStyles, setBodyStyles] = useState({});

    useEffect(() => {
        function getScrollbarWidth() {
            var scrollDiv = document.createElement("div");
            scrollDiv.className = "scrollbar-measure";
            document.body.appendChild(scrollDiv);
    
            // Get the scrollbar width
            var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
            
            // Delete the DIV 
            document.body.removeChild(scrollDiv);
            return scrollbarWidth;
        }
        
        var actualWidth = 1920 - getScrollbarWidth();

        if (isDesktop) {
            setBodyStyles({
                "font-family": "Poppins, sans-serif",
                width: `${actualWidth}px`,
                margin: "0 auto",
            });
        } else {
            setBodyStyles({
                "font-family": "Poppins, sans-serif",
            });
        }
    }, [isDesktop]);

    return (
        <HashRouter>
            <ShowSideBar />
            <Suspense fallback={loading}>
                <style>
                    {`
                        body {
                            ${Object.entries(bodyStyles).map(([key, value]) => `${key}: ${value};`).join('\n')}
                        }
                    `}
                </style>
                <Routes>
                    <Route exact path="/logout" name="Login Page" element={<Logout />} />
                    <Route exact path="/login" name="Login Page" element={<Login />} />
                    <Route exact path="/register" name="Register Page" element={<Register />} />
                    <Route exact path="/404" name="Page 404" element={<Page404 />} />
                    <Route exact path="/500" name="Page 500" element={<Page500 />} />
                    <Route exact path="/forgot-password" name="Forgot Password" element={<ForgotPassword />} />
                    <Route exact path="/forgot-password-sent" name="Forgot Password Sent" element={<ForgotPasswordSent />} />
                    <Route exact path="/reset-password/:token" name="Reset Password" element={<ResetPassword />} />
                    <Route exact path="/reset-password-success" name="Reset Password Success" element={<ResetPasswordSuccess />} />
                    <Route path="home" name="Home" element={<DefaultLayout />}>
                        {loggedIn?.users?.roleName === USER_ROLE.ADMIN &&
                            adminRoute.map((route, idx) => {
                                return route.element && <Route key={idx} path={route.path} name={route.name} element={<route.element />} />;
                        })}

                        {loggedIn?.users?.roleName === USER_ROLE.INVESTOR &&
                            investorRoute.map((route, idx) => {
                                return route.element && <Route key={idx} path={route.path} name={route.name} element={<route.element />} />;
                        })}
                    </Route>
                    <Route path="/" name="Home" element={<DefaultLayout />} />
                    <Route path="*" name="Not Found" element={<Page404 />} />
                </Routes>
            </Suspense>
        </HashRouter>
    );
};

export default App;
