import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const campaignSlide = createSlice({
    name: "campaign",
    initialState: {
        page: 0,
        pageCount: 0,
        success: false,
        fail: false,
        listData: null,
        detailData: null,
        responseData: null,
        errorDetailData: null,

        updateProductSuccess: false,
        updateProductFail: false,
    },
    reducers: {
        doGetCampaignListSuccess: (state, action) => {
            state.listData = action.payload.data;
            state.page = action.payload.data?.current_page;
			state.pageCount = action.payload.data?.page_count;
        },
        doGetCampaignListFail: (state, action) => {
            state.listData = null;
            state.page = 0;
			state.pageCount = 0;
        },
        doGetCampaignDetailSuccess: (state, action) => {
            state.detailData = action.payload.data;
        },
        doGetCampaignDetailFail: (state, action) => {
            state.detailData = null;
        },
        doUpdateRaffleBannerSuccess: (state, action) => {
            state.success = true;
            state.fail = false;
        },
        doUpdateRaffleBannerFail: (state, action) => {
            state.success = false;
            state.fail = true;
			state.errorDetailData = action.payload.data;
        },
        doUpdateProductSuccess: (state, action) => {
            state.updateProductSuccess = true;
            state.updateProductFail = false;
        },
        doUpdateProductFail: (state, action) => {
            state.updateProductSuccess = false;
            state.updateProductFail = true;
			state.errorDetailData = action.payload.data;
        },
        resetCampaignStateSuccess: (state, action) => {
            state.success = action.payload;
            state.fail = action.payload;
			state.errorDetailData = null;
            state.updateProductSuccess = action.payload;
            state.updateProductFail = action.payload;
        },
    },
});

export const doGetCampaignList = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/campaign?page=${param.page}&size=${param.limit}`;
        if(param.name) {
            url += `&name=${encodeURIComponent(param.name)}`;
        }

        const response = await axios.get(url);
        dispatch(doGetCampaignListSuccess(response.data));
    } catch (error) {
        dispatch(doGetCampaignListFail(error.response));
    }
};

export const doGetCampaignDetail = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/campaign/${param.campaignId}`;
       
        const response = await axios.get(url);
        dispatch(doGetCampaignDetailSuccess(response.data));
    } catch (error) {
        dispatch(doGetCampaignDetailFail(error.response));
    }
};

export const doUpdateRaffleBanner = (param) => async (dispatch) => {
    try {
        const url = `/api/admin/campaign/raffle-banner`;

        const response = await axios.put(url, param);
        dispatch(doUpdateRaffleBannerSuccess(response.data));
    } catch (error) {
        dispatch(doUpdateRaffleBannerFail(error.response));
    }
};

export const doUpdateProduct = (param) => async (dispatch) => {
    try {
        const url = `/api/admin/campaign/product`;

        const response = await axios.put(url, param);
        dispatch(doUpdateProductSuccess(response.data));
    } catch (error) {
        dispatch(doUpdateProductFail(error.response));
    }
};

export const resetCampaignState = () => async (dispatch) => {
    dispatch(resetCampaignStateSuccess(false));
 };

export const { 
    doGetCampaignListSuccess, 
    doGetCampaignListFail, 
    doGetCampaignDetailSuccess,
    doGetCampaignDetailFail,
    doUpdateRaffleBannerSuccess,
    doUpdateRaffleBannerFail,
    doUpdateProductSuccess,
    doUpdateProductFail,
    resetCampaignStateSuccess,
} = campaignSlide.actions;

export const getCampaignList = (state) => state.campaign?.listData;
export const getCampaignListPage = (state) => state.campaign?.page;
export const getCampaignListPageCount = (state) => state.campaign?.pageCount;
export const getCampaignDetail = (state) => state.campaign?.detailData;
export const getUpdateRaffleBannerSuccess = (state) => state.campaign?.success;
export const getUpdateRaffleBannerFail = (state) => state.campaign?.fail;
export const getUpdateRaffleBannerErrorDetailData = (state) => state.campaign?.errorDetailData;

export const getUpdateProductSuccess = (state) => state.campaign?.updateProductSuccess;
export const getUpdateProductFail = (state) => state.campaign?.updateProductFail;

export default campaignSlide.reducer;
