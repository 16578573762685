import user_management_route from "./modules/user_management_route";
import my_profile_route from "./modules/my_profile_route";
import dashboard_route from "./modules/dashboard_route";
import home_route from "./modules/home_route";

const routes = [
    // ...user_management_route, 
    ...my_profile_route, 
    ...dashboard_route,
    ...home_route,
];

export default routes;