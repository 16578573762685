import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const userManagementSlide = createSlice({
    name: "userManagement",
    initialState: {
        page: 0,
        pageCount: 0,
        success: false,
        fail: false,
        listData: null,
        detailData: null,
        responseData: null,
        errorDetailData: null,
    },
    reducers: {
        doGetUserManagementListSuccess: (state, action) => {
            state.listData = action.payload.data;
            state.page = action.payload.data?.current_page;
			state.pageCount = action.payload.data?.page_count;
        },
        doGetUserManagementListFail: (state, action) => {
            state.listData = null;
            state.page = 0;
			state.pageCount = 0;
        },
        doGetUserManagementDetailSuccess: (state, action) => {
            state.detailData = action.payload.data;
        },
        doGetUserManagementDetailFail: (state, action) => {
            state.detailData = null;
        },
        doAddUserManagementSuccess: (state, action) => {
            state.success = true;
            state.fail = false;
			state.responseData = action.payload;
        },
        doAddUserManagementFail: (state, action) => {
            state.success = false;
            state.fail = true;
			state.errorDetailData = action.payload;
        },

        doUpdateUserManagementSuccess: (state, action) => {
            state.success = true;
            state.fail = false;
        },
        doUpdateUserManagementFail: (state, action) => {
            state.success = false;
            state.fail = true;
			state.errorDetailData = action.payload;
        },
        resetUserManagementStateSuccess: (state, action) => {
            state.success = action.payload;
            state.fail = action.payload;
            state.detailData = null;
			state.errorDetailData = null;
        },
    },
});

export const doGetUserManagementList = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/user-management?page=${param.page}&size=${param.limit}`;
        if(param.name) {
            url += `&name=${encodeURIComponent(param.name)}`;
        }

        const response = await axios.get(url);
        dispatch(doGetUserManagementListSuccess(response.data));
    } catch (error) {
        dispatch(doGetUserManagementListFail(error.response));
    }
};

export const doGetUserManagementDetail = (param) => async (dispatch) => {
    try {
        let url = `/api/admin/user-management/${param.user_id}`;
       
        const response = await axios.get(url);
        dispatch(doGetUserManagementDetailSuccess(response.data));
    } catch (error) {
        dispatch(doGetUserManagementDetailFail(error.response));
    }
};

export const doAddUserManagement = (param) => async (dispatch) => {
    try {
        const url = `/api/admin/user-management`;

        const response = await axios.post(url, param);
        dispatch(doAddUserManagementSuccess(response.data));
    } catch (error) {
        dispatch(doAddUserManagementFail(error.response));
    }
};

export const doUpdateUserManagement = (param, userId) => async (dispatch) => {
    try {
        const url = `/api/admin/user-management/${userId}`;

        const response = await axios.put(url, param);
        dispatch(doUpdateUserManagementSuccess(response.data));
    } catch (error) {
        dispatch(doUpdateUserManagementFail(error.response));
    }
};


export const resetUserManagementState = () => async (dispatch) => {
    dispatch(resetUserManagementStateSuccess(false));
 };

export const { 
    doGetUserManagementListSuccess, 
    doGetUserManagementListFail, 
    doGetUserManagementDetailSuccess,
    doGetUserManagementDetailFail,
    doAddUserManagementSuccess,
    doAddUserManagementFail,
    doUpdateUserManagementSuccess,
    doUpdateUserManagementFail,
    resetUserManagementStateSuccess,
} = userManagementSlide.actions;

export const getUserManagementList = (state) => state.userManagement?.listData;
export const getUserManagementListPage = (state) => state.userManagement?.page;
export const getUserManagementListPageCount = (state) => state.userManagement?.pageCount;
export const getUserManagementDetail = (state) => state.userManagement?.detailData;
export const getUserManagementErrorDetailData = (state) => state.userManagement?.errorDetailData;
export const getAddUserManagementSuccess = (state) => state.userManagement?.success;
export const getAddUserManagementFail = (state) => state.userManagement?.fail;
export const getAddUserManagementResponseData = (state) => state.userManagement?.responseData;

export default userManagementSlide.reducer;
