import React from "react";

const MyCampaign = React.lazy(() => import("src/pages/my_campaign/MyCampaign"));
const CampaignManagement = React.lazy(() => import("src/pages/my_campaign/campaign_management/index"));
const CampaignStatistic = React.lazy(() => import("src/pages/my_campaign/campaign_statistic/index"));

const routes = [
    { path: "/home/my-campaign/campaign-statistic", name: "{campaign_name} Statistic", element: CampaignStatistic },
    { path: "/home/my-campaign/campaign-management", name: "Campaign Management / {campaign_name} Configuration", element: CampaignManagement },
    { path: "/home/my-campaign", name: "Home / My Campaign", element: MyCampaign },
];

export default routes;
