import React from "react";

const ViewMyProfile = React.lazy(() => import('src/pages/my_profile/ViewMyProfile'));
const EditMyProfile = React.lazy(() => import('src/pages/my_profile/EditMyProfile'));

const routes = [
    { path: '/home/my-profile/edit', name: 'Edit', element: EditMyProfile },
    { path: '/home/my-profile', name: "My Profile", element: ViewMyProfile },
];

export default routes;