import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const orderSlide = createSlice({
    name: "statistic",
    initialState: {
        customerList: null,
        campaignOrderList: null,
        campaignRaffleList: null,
    },
    reducers: {
        doGetCustomerListSuccess: (state, action) => {
            state.customerList = action.payload.data;
        },
        doGetCustomerListFail: (state, action) => {
            state.customerList = null;
        },
        doGetCampaignOrderListSuccess: (state, action) => {
            state.campaignOrderList = action.payload.data;
        },
        doGetCampaignOrderListFail: (state, action) => {
            state.campaignOrderList = null;
        },
        doGetCampaignRaffleListSuccess: (state, action) => {
            state.campaignRaffleList = action.payload.data;
        },
        doGetCampaignRaffleListFail: (state, action) => {
            state.campaignRaffleList = null;
        },
    },
});


export const doGetCustomerList = (campaign_id) => async (dispatch) => {
    try {
        const url = `/api/admin/campaign/statistic/${campaign_id}/customer`;
        const response = await axios.get(url);
        dispatch(doGetCustomerListSuccess(response.data));
    } catch (error) {
        dispatch(doGetCustomerListFail(error.response));
    }
};

export const doGetCampaignOrderList = (campaign_id) => async (dispatch) => {
    try {
        const url = `/api/admin/campaign/statistic/${campaign_id}/order`;
        const response = await axios.get(url);
        dispatch(doGetCampaignOrderListSuccess(response.data));
    } catch (error) {
        dispatch(doGetCampaignOrderListFail(error.response));
    }
};

export const doGetCampaignRaffleList = (campaign_id) => async (dispatch) => {
    try {
        const url = `/api/admin/campaign/statistic/${campaign_id}/raffle`;
        const response = await axios.get(url);
        dispatch(doGetCampaignRaffleListSuccess({data: response.data}));
    } catch (error) {
        dispatch(doGetCampaignRaffleListFail(error.response));
    }
};

export const { 
    doGetCustomerListSuccess,
    doGetCustomerListFail,
    doGetCampaignOrderListSuccess,
    doGetCampaignOrderListFail,
    doGetCampaignRaffleListSuccess,
    doGetCampaignRaffleListFail,
} = orderSlide.actions;

export const getCustomerList = (state) => state.statistic?.customerList;
export const getCampaignOrderList = (state) => state.statistic?.campaignOrderList;
export const getCampaignRaffleList = (state) => state.statistic?.campaignRaffleList;

export default orderSlide.reducer;
